import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import CostBreakdownTable from './CostBreakdownTable';
import { fetchQuote, createQuote } from '../services/quoteService';
import { createNotification } from "../services/dbService";
import { doc, updateDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { SystemCommenter } from '../Shared/SystemCommenter';
import { generateQuoteEmailHTMLTemplate, generateQuoteEmailTextTemplate } from '../EmailTemplates/emailTemplates';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { titleDecorator } from '../Shared/titleDecorator';
import styles from '../styles/QuoteForm.module.css'; // Use CSS modules

const QuoteForm = ({ jobData, onClose, readOnly = false }) => {
  const [quoteDetails, setQuoteDetails] = useState('');
  const [remainingChars, setRemainingChars] = useState(4000);
  const [costRows, setCostRows] = useState([{ id: 1, type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }]);
  const [leadTime, setLeadTime] = useState('');
  const [duration, setDuration] = useState('');
  const [quoteExpiryDate, setQuoteExpiryDate] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('');
  const [totalQuoteAmount, setTotalQuoteAmount] = useState(0);
  const { user } = useAuthContext();
  const titleBackgroundColour = 'var(--cmh-title-soft-background-color)';

  useEffect(() => {
    const loadData = async () => {
      if (jobData) {
        try {
          const quoteData = await fetchQuote(jobData.jobId);
          if (quoteData) {
            setQuoteDetails(quoteData.quoteDetails);
            setLeadTime(quoteData.leadTime);
            setDuration(quoteData.duration);
            setQuoteExpiryDate(
              quoteData.quoteExpiryDate
                ? new Date(quoteData.quoteExpiryDate).toISOString().split('T')[0]
                : null
            );
            setCostRows(quoteData.costBreakdown || []);
          }
        } catch (error) {
          console.error('Error fetching quote details:', error);
        }
      }
    };
    loadData();
  }, [jobData]);

  const handleQuoteDetailsChange = (e) => {
    if (!readOnly) {
      const text = e.target.value;
      setQuoteDetails(text);
      setRemainingChars(4000 - text.length);
    }
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const addCostRow = () => {
    if (!readOnly) {
      setCostRows([...costRows, { id: Date.now(), type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }]);
    }
  };

  const removeCostRow = (id) => {
    if (!readOnly) {
      setCostRows(costRows.filter((row) => row.id !== id));
    }
  };

  const handleRowChange = (id, field, value) => {
    if (!readOnly) {
      setCostRows((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row, [field]: value };
            const net = parseFloat(updatedRow.net) || 0;
            const vatAmount = parseFloat(updatedRow.vatAmount) || 0;
            updatedRow.total = net + vatAmount;
            return updatedRow;
          }
          return row;
        });

        const newTotalAmount = updatedRows.reduce((sum, row) => sum + (parseFloat(row.total) || 0), 0);
        setTotalQuoteAmount(newTotalAmount);

        return updatedRows;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!readOnly) {
      const currentTime = serverTimestamp();
      const quoteData = {
        jobId: jobData.jobId,
        landlordEmail: jobData.landlordEmail,
        trade: jobData.tradeName,
        instructionNotes: jobData.instructionNotes,
        furtherDetails: jobData.furtherDetails,
        leadTime,
        duration,
        quoteExpiryDate: quoteExpiryDate ? new Date(quoteExpiryDate).getTime() : null,
        quoteDetails,
        costBreakdown: costRows,
        createdAt: currentTime,
        submittedBy: user.email,
      };

      try {
        await createQuote(jobData.jobId, quoteData, 1);

        const jobRef = doc(db, 'jobs', jobData.jobId);
        await updateDoc(jobRef, {
          status: 1,
          quoteAmount: totalQuoteAmount,
          quoteTime: currentTime,
        });

        await SystemCommenter(jobData.jobId, 'Admin', 'A quote has been provided and sent for the job.');

        const plainTextBody = generateQuoteEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
        const htmlBody = generateQuoteEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);

        await sendNotificationEmail({
          to: jobData.landlordEmail,
          subject: `${globalConfig.sitename} quote - Job ${jobData.jobId}`,
          text: plainTextBody,
          html: htmlBody,
        });

        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
        const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

        // Create Notifications
        await createNotification(jobData.jobId, "quote_provided", {
          admin: null,
          agent: jobData.agentId, 
          landlord: jobData.landlordEmail,
          contractor: null 
        }, propertyAddress,
      );


        setSubmitMessage('Quote submitted successfully!');
        setTimeout(() => {
          setSubmitMessage('');
          onClose();
        }, 3000);
      } catch (error) {
        console.error('Error submitting quote:', error);
        setSubmitMessage('Error submitting quote. Please try again.');
      }
    }
  };

  return (
    <form className={styles.quoteForm} onSubmit={handleSubmit}>
      <h2>{jobData.jobId}: Work Required</h2>

      {/* Job Requirement Row */}
      <div className={styles.threeColumnRow}>
        <div className={styles.formGroup}>
          <label>{titleDecorator("Trade:", titleBackgroundColour)}</label>
          <p>{jobData?.tradeName}</p>
        </div>
        <div className={styles.formGroup}>
          <label>{titleDecorator("Job Description:", titleBackgroundColour)}</label>
          <p>{jobData?.jobDetail}</p>
        </div>
        <div className={styles.formGroup}>
          <label>{titleDecorator("Further Information:", titleBackgroundColour)}</label>
          <p>{jobData?.furtherDetails}</p>
        </div>
      </div>

{/* Job Images Section */}
{jobData?.photos?.length > 0 && (
  <div className={styles.imageSection}>
    <h3>Job Images</h3>
    <div className={styles.imageGrid}>
      {jobData.photos.map((photo, index) => (
        <div
          key={index}
          className={styles.imageContainer}
          onClick={() => {
            const screenWidth = Math.floor(window.innerWidth * 0.8);
            const screenHeight = Math.floor(window.innerHeight * 0.8);
            const left = (window.innerWidth - screenWidth) / 2;
            const top = (window.innerHeight - screenHeight) / 2;
            window.open(
              photo,
              "_blank",
              `width=${screenWidth},height=${screenHeight},top=${top},left=${left}`
            );
          }}
        >
          <img src={photo} alt={`Job example ${index + 1}`} className={styles.jobImage} />
        </div>
      ))}
    </div>
  </div>
)}

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label>Lead time required</label>
          <input type="text" value={leadTime} onChange={(e) => setLeadTime(e.target.value)} disabled={readOnly} />
        </div>
        <div className={styles.formGroup}>
          <label>Est. duration</label>
          <input type="text" value={duration} onChange={(e) => setDuration(e.target.value)} disabled={readOnly} />
        </div>
        <div className={styles.formGroup}>
          <label>Date quote expires</label>
          <input type="date" value={quoteExpiryDate || ''} onChange={(e) => setQuoteExpiryDate(e.target.value)} disabled={readOnly} />
        </div>
      </div>

      <div className={styles.textareaGroup}>
        <label>Quote Detail</label>
        <textarea rows="4" value={quoteDetails} onChange={handleQuoteDetailsChange} disabled={readOnly} />
        <div className={styles.textareaInfo}>
          <small>{remainingChars} characters remaining</small>
        </div>
      </div>

      <CostBreakdownTable
        costRows={costRows}
        onRowChange={handleRowChange}
        onAddRow={addCostRow}
        onRemoveRow={removeCostRow}
        readOnly={readOnly}
      />

      {!readOnly && user?.role === 'Admin' && (
        <div className={styles.submitBtn}>
          <button type="submit">Submit Quote</button>
        </div>
      )}

      {submitMessage && <div className={styles.submitMessage}>{submitMessage}</div>}
    </form>
  );
};

export default QuoteForm;
