import React, { useRef, useEffect, useState, Suspense } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth } from "../../firebase";
import { ReactComponent as ReactSprite } from "../../assets/sprite.svg";
import { fetchUnreadNotificationCount } from "../../services/dbService";
import "../../styles/SideNav.css";
import AdminNavLinks from "../../Navlinks/AdminNavLinks";
import AgentNavLinks from "../../Navlinks/AgentNavLinks";
import LandlordNavLinks from "../../Navlinks/LandlordNavLinks";
import ContractorNavLinks from "../../Navlinks/ContractorNavLinks";

const SideNav = ({ 
  isCollapsed, 
  isDarkMode, 
  toggleDarkMode,
  isContractor,
  onDashboardClick,
  refreshDashboard,
  onNotificationClick,        
}) => {

  const [role, setRole] = useState(null);
  const menuLinksRef = useRef([]);
  const [unreadCount, setUnreadCount] = useState(0);
    
  // Fetch user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!auth.currentUser) {
        console.error("User not authenticated");
        setRole(null);
        return;
      }

      const email = auth.currentUser.email;
      try {
        const db = getFirestore();
        const userDoc = doc(db, "users", email);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists()) {
          setRole(userSnap.data().role || null);
        } else {
          console.error("No user document found!");
          setRole(null);
        }
      } catch (error) {
        console.error("Failed to fetch user role:", error);
        setRole(null);
      }
    };

    fetchUserRole();
  }, []);

  // Fetch unread notification count (Real-time updates)
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("SideNav: User authenticated, fetching notifications...");
        const unsubscribeNotifications = fetchUnreadNotificationCount(setUnreadCount);
  
        return () => {
          console.log("SideNav: Cleaning up notification listener...");
          unsubscribeNotifications && unsubscribeNotifications();
        };
      } else {
        console.warn("SideNav: No authenticated user found.");
        setUnreadCount(0);
      }
    });
  
    return () => unsubscribeAuth();
  }, []);


  const handleLinkHover = (event) => {
    if (isCollapsed) {
      const tooltip = event.currentTarget.querySelector("span").textContent;
      event.currentTarget.setAttribute("title", tooltip);
    } else {
      event.currentTarget.removeAttribute("title");
    }
  };

  return (
    <header className={`side-panel ${isCollapsed ? "collapsed" : ""}`}>
      <nav>
        <ReactSprite />
        <ul className="side-menu">
          <Suspense fallback={<div>Loading...</div>}>
            {role?.toLowerCase() === "admin" && (
              <AdminNavLinks menuLinksRef={menuLinksRef} onHover={handleLinkHover} unreadCount={unreadCount} onDashboardClick={onDashboardClick} onNotificationClick={onNotificationClick}/>
            )}
            {role?.toLowerCase() === "agent" && (
              <AgentNavLinks menuLinksRef={menuLinksRef} onHover={handleLinkHover} unreadCount={unreadCount} onDashboardClick={onDashboardClick} onNotificationClick={onNotificationClick}/>
            )}
            {role?.toLowerCase() === "landlord" && (
              <LandlordNavLinks menuLinksRef={menuLinksRef} onHover={handleLinkHover} unreadCount={unreadCount} onDashboardClick={onDashboardClick} onNotificationClick={onNotificationClick}/>
            )}
            {role?.toLowerCase() === "contractor" && (
              <ContractorNavLinks menuLinksRef={menuLinksRef} onHover={handleLinkHover} unreadCount={unreadCount} onDashboardClick={onDashboardClick} onNotificationClick={onNotificationClick}/>
            )}
          </Suspense>
        </ul>
      </nav>
    </header>
  );
};

export default SideNav;
