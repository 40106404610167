import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext';
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import QuoteForm from '../QuoteForm/QuoteForm';
import PropertyDetail from '../PropertyInformation/PropertyDetail';
import PropertyDetailsPanel from '../PropertyDetailsPanel/PropertyDetailsPanel'; 
import { fetchPropertyById, updateJobStatus, updateJobBypassHold } from '../services/dbService';
import { assignJobToContractor, createNotification, fetchContractorsBySubscriptionType } from "../services/dbService";
import AppointmentForm from '../AppointmentForm/AppointmentForm';
import '../styles/JobTabs.css';
import { notifyLandlord } from '../services/quoteService';
import { SystemCommenter} from '../Shared/SystemCommenter'
import { globalConfig } from '../config';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const JobTabs = ({ jobData, onReturnToDashboard, tab, isAdmin, tabModes }) => {

  const { user } = useAuthContext(); 
  const isLandlord = user?.role === 'Landlord';
  const isContractor = user?.role === 'Contractor';
  // const [activeTab, setActiveTab] = useState(tab || 'Detail');
  const [propertyDetails, setPropertyDetails] = useState('');
  const [isAssignable, setIsAssignable] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [contractors, setContractors] = useState([]);

  useEffect(() => {
    const loadContractors = async () => {
      try {
        const contractorsList = await fetchContractorsBySubscriptionType(0);
        debugger;
        setContractors(contractorsList);
      } catch (error) {
        alert("Error loading contractors: " + error.message);
      }
    };

    if (showDropdown) {
      // alert('showDropdown');
      loadContractors();
    }
  }, [showDropdown]);

  useEffect(() => {
    if (jobData?.status === 2) {
      // alert("Job status is 2");
      setIsAssignable(true);
    }
  }, [jobData]);
  
  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  const handleAcceptQuote = async () => {
    try {
        // Step 2: Update job status to '2' (Accepted) after payment is confirmed
        await updateJobStatus(jobData.jobId, 2); // Status '2' represents an accepted quote.

        // Step 3: Notify the landlord or perform any other relevant actions
        notifyLandlord(jobData.landlordEmail, `Quote for Job ${jobData.jobId} has been accepted.`);
    
        // Step 4: Add system comment
        await SystemCommenter(
          jobData.jobId, 
          'Admin', 
          'The quote has been accepted for this job.'
        );

        debugger;
        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(jobData.propertyId);
        const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;


        // Step 5: Create a Notifications
          await createNotification(jobData.jobId, "quote_accepted", {
            admin: globalConfig.adminEmail, // Notify admin
            agent: jobData.agentId, // Notify the agent
            landlord: null, // No need to notify landlord
            contractor: null // Not needed for this event
          }, propertyAddress,
        );

    
      // Step 5: Provide feedback and close the form
      // setFeedback('Quote accepted successfully!');
      onReturnToDashboard(); 
 
    } catch (error) {
      console.error('Error accepting the quote:', error);
      alert('Error accepting the quote: ' + error);
    }
  };

  const landlordData = {
    landlordFirstname: jobData.landlordFirstName,
    landlordLastname: jobData.landlordLastName,
    landlordEmail: jobData.landlordEmail,
  };
  
  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);

  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');
  // const [activeTab, setActiveTab] = useState(tab || tabs[0]?.name || "Detail");
  const [activeTab, setActiveTab] = useState(tab || (tabModes.length > 0 ? tabModes[0].name : "Detail"));

  const tabs = [
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },
    { name: 'Quote', component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord /> },
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} isActive={activeTab === 'Comments'} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <AppointmentForm jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} landlordData={landlordData} /> },
    { name: 'Property', component: <PropertyDetail propertyId={jobData.propertyId} readOnly={tabModes?.Property === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name));

  const handleContractorAcceptJob = async () => {
    // alert('handleContractorAcceptJob');

    const options = {
      jobId: jobData.jobId,
      contractorName: "One Love Painting & Decorating Ltd",
    };
    try {
      await assignJobToContractor(options);
      onReturnToDashboard();
    } catch (error) {
      alert(error.message);
    }
  };

  // const handleContractorSubmitAppointmentDate = async () => {
  //   try {
  //     await updateJobStatus(jobData.jobId, 4); // appointment sent
  //     onReturnToDashboard();
  //   } catch (error) {
  //     console.error('Error sending proposed appointment date: ', error);
  //     alert('Error sending proposed appointment date: ' + error);
  //   }
  // };

  const handleAssignInHouse = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };
  
  const handleRejectQuote = async () => {
    try {
      await updateJobStatus(jobData.jobId, -1);
      onReturnToDashboard();
    } catch (error) {
      console.error('Error rejecting the quote:', error);
      alert('Error-005: Failed to reject the quote. Please try again.');
    }
  };

  const handleReleaseJob = async (jobId) => {
    console.log('JobTabs:handleReleaseJob: ' + jobId);
    try {
      await updateJobBypassHold(jobId);
      console.log(`Job ${jobId} successfully released.`);
      onReturnToDashboard(); 
    } catch (error) {
      alert(`Error updating job: ${error.message}`);
    }

  };

  return (
    <div className="job-tabs-container">

      {showDropdown && (
        <select style={{ marginTop: "10px" }}>
          <option value="">-- Select a Contractor --</option>
          {contractors.map((contractor) => (
            <option key={contractor.id} value={contractor.id}>
              {contractor.name}
            </option>
          ))}
        </select>
      )}

      <PropertyDetailsPanel propertyDetails={propertyDetails} />
      
      <div className="job-tabs-button-group">
        <div className="tabs-left">
          {tabs.map(({ name }) => <button key={name} onClick={() => setActiveTab(name)} className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}>{name}</button>)}
        </div>
        <div className="buttons-right">
          {(isAdmin || isLandlord || isContractor) && (
            <>
              {isLandlord && jobData.status === 1 && (
                <>
                  <button className="negative-button" onClick={handleRejectQuote}>Reject Quote</button>
                  <button className="positive-button" onClick={handleAcceptQuote}>Accept Quote and Pay</button>
                </>
              )}

              {isAssignable && isAdmin && !isContractor && (
                <>
                <button className="negative-button" onClick={() => handleReleaseJob(jobData.jobId)} style={{ marginLeft: 'auto' }}>Release Job to Gold Contractors</button>
                <button className="negative-button" onClick={handleAssignInHouse} style={{ marginLeft: 'auto' }}>Assign job to Platinum Contractor</button>
                </>
              )}
              
              {isContractor && jobData.status === 2 && (
                <button className="negative-button" onClick={handleContractorAcceptJob} style={{ marginLeft: 'auto' }}>Accept job</button>
              )}

              {/* {isContractor && jobData.status === 3 && (
                <button className="negative-button" onClick={handleContractorSubmitAppointmentDate} style={{ marginLeft: 'auto' }}>Submit Appointment date and time to Landlord</button>
              )} */}
              
              <button className="negative-button" onClick={onReturnToDashboard} style={{ marginLeft: 'auto' }}>Return to Dashboard</button>
              </>
          )}
        </div>
      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected</p>}
      </div>
    </div>
  );
};

export default JobTabs;
