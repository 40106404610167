import React, { useState, useEffect } from "react";
import { fetchJobById, fetchPropertyById, fetchWorkHistoryByPropertyId } from "../services/dbService";
import { STATUS_MAPPING } from "../services/dbService";
import styles from "./JobDetailForm.module.css";
import { titleDecorator } from '../Shared/titleDecorator';

const JobDetailForm = ({ jobId }) => {
  const [job, setJob] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]);
  const [jobImages, setJobImages] = useState([]);  // State for job-specific images
  const [propertyLocation, setPropertyLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("propertyImages");
  const [propertyFloorplans, setPropertyFloorplans] = useState([]);
  const [propertyWorks, setPropertyWorks] = useState([]);
  const titleBackgroundColour = 'var(--cmh-title-soft-background-color)';
  const googleMapsApiKey = "AIzaSyAftNcYYOuB3c3oHs4ZqVD6E8V5U10G63M";

  useEffect(() => {
    const loadJobAndProperty = async () => {
      try {
        const fetchedJob = await fetchJobById(jobId);
        setJob({ ...fetchedJob });
        setJobImages(fetchedJob?.photos || []);  // Set job-specific images
        if (fetchedJob?.propertyId) {
          const property = await fetchPropertyById(fetchedJob.propertyId);
          setPropertyImages(property?.propertyImages || []);
          setPropertyFloorplans(property?.propertyFloorplans || []);
          setPropertyLocation(`${property?.address || ""}, ${property?.postcode || ""}`.trim());
        }
      } catch (err) {
        console.error("Error loading job or property:", err);
        setError("Failed to load job details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    loadJobAndProperty();
  }, [jobId]);

  useEffect(() => {
    const loadWorkHistory = async () => {
      try {
        const works = await fetchWorkHistoryByPropertyId(job?.propertyId || "");
        setPropertyWorks(works);
      } catch (error) {
        console.error("Failed to load work history:", error);
      }
    };
    loadWorkHistory();
  }, [job]);

  if (loading) return <p>Loading job details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <h2>{job.jobId}: Job Detail</h2>
      <div className={styles.jobDetailContainer}>
        <form>
          <label htmlFor="jobDescription">{titleDecorator("Job Description:", titleBackgroundColour)}</label>
          <textarea id="jobDescription" name="jobDescription" rows="2" value={job?.jobDetail || ""} readOnly></textarea>
          
          <label htmlFor="furtherInformation">{titleDecorator("Further Information:", titleBackgroundColour)}</label>
          <textarea id="furtherInformation" name="furtherInformation" rows="2" value={job?.furtherDetails || ""} readOnly></textarea>
          
          {/* Job-Specific Images Section */}
          {jobImages.length > 0 && (
            <div className={styles.jobImagesSection}>
              <label>{titleDecorator("Job Images:", titleBackgroundColour)}</label>
              <div className={styles.imageGrid}>
                {jobImages.map((image, index) => (
                  <div 
                    key={index} 
                    className={styles.imageContainer}
                    onClick={() => {
                      const screenWidth = window.innerWidth * 0.95;
                      const screenHeight = window.innerHeight * 0.95;
                      const imageHTML = `
                        <html>
                          <head>
                            <style>
                              html, body {
                                margin: 0;
                                padding: 0;
                                background: black;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                              }
                              img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                              }
                            </style>
                          </head>
                          <body>
                            <img src="${image}" alt="Job Image">
                          </body>
                        </html>
                      `;
                      const newWindow = window.open(
                        '',
                        '_blank',
                        `width=${screenWidth},height=${screenHeight},top=${(window.innerHeight - screenHeight) / 2},left=${(window.innerWidth - screenWidth) / 2}`
                      );
                      newWindow.document.write(imageHTML);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={image} alt={`Job display ${index + 1}`} className={styles.propertyImage} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={styles.jobDetailRow}>
            <div className={styles.formGroup}>
              <label htmlFor="category">{titleDecorator("Category", titleBackgroundColour)}</label>
              <input type="text" id="category" name="category" value={job?.tradeName || ""} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="createdDate">{titleDecorator("Created", titleBackgroundColour)}</label>
              <input type="text" id="createdDate" name="createdDate" value={job?.createdAt ? new Date(job.createdAt.seconds * 1000).toLocaleDateString("en-GB") : ""} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="preQuotedWork">{titleDecorator("Pre-Quoted Work", titleBackgroundColour)}</label>
              <input type="text" id="preQuotedWork" name="preQuotedWork" value={job?.isPQW ? "Yes" : "No"} readOnly />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="status">{titleDecorator("Status", titleBackgroundColour)}</label>
              <input type="text" id="status" name="status" value={STATUS_MAPPING[job?.status]?.label || STATUS_MAPPING.default.label} readOnly />
            </div>
          </div>
        </form>

        {/* Tabs for Property Images, Location, and Floorplans */}
        <div className={styles.tabs}>
          <button className={`${styles.tabButton} ${activeTab === "propertyImages" ? styles.active : ""}`} onClick={() => setActiveTab("propertyImages")}>Property Images</button>
          <button className={`${styles.tabButton} ${activeTab === "propertyLocation" ? styles.active : ""}`} onClick={() => setActiveTab("propertyLocation")}>Location</button>
          {propertyFloorplans.length > 0 && (
            <button className={`${styles.tabButton} ${activeTab === "floorplan" ? styles.active : ""}`} onClick={() => setActiveTab("floorplan")}>Floorplan</button>
          )}
        </div>

        {activeTab === "propertyImages" && (
          <div className={styles.propertyImagesSection}>
            <h3>Property Images</h3>
            <div className={styles.imageGrid}>
              {propertyImages.map((image, index) => (
                <div key={index} className={styles.imageContainer}>
                  <img src={image} alt={`Property ${index + 1}`} className={styles.propertyImage} />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "floorplan" && (
          <div className={styles.floorplanSection}>
            <h3>Floorplan</h3>
            <div className={styles.imageGrid}>
              {propertyFloorplans.map((url, index) => (
                <div key={index} className={styles.imageContainer}>
                  <img src={url} alt={`Floorplan ${index + 1}`} className={styles.propertyImage} />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "propertyLocation" && (
          <div className={styles.propertyLocationSection}>
            <h3>Property Location</h3>
            {propertyLocation ? (
              <iframe
                title="Property Location"
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${encodeURIComponent(propertyLocation)}`}
                width="100%"
                height="300"
                style={{ border: "none", borderRadius: "8px" }}
                allowFullScreen
              ></iframe>
            ) : (
              <p>No location data available for this property.</p>
            )}
          </div>
        )}

        {activeTab === "relationship" && (
          <div className={styles.relationshipSection}>
            <h3>Work History</h3>
            {propertyWorks.length > 0 ? (
              <ul className={styles.workList}>
                {propertyWorks.map((work) => (
                  <li key={work.id} className={styles.workItem}>
                    <p><strong>{work.jobDescription || "No description available"}</strong></p>
                    <p>Date: {work.date ? new Date(work.date.seconds * 1000).toLocaleDateString("en-GB") : "No date available"}</p>
                    <p>Status: {work.status || "No status available"}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No work history found for this property.</p>
            )}
          </div>
        )}


      </div>
    </>
  );
};

export default JobDetailForm;
