import React, { useState, useEffect, useRef } from 'react';
// import LandlordManageProperties from './LandlordManageProperties';
import PopForm from '../PopForm/PopForm';
import { getPropertiesByEmail } from '../services/propertyService';
import { fetchPreQuotedWorks } from '../services/dbService';
import JobTabs from '../Job/JobTabs'; // Ensure the path is correct
import '../styles/LandlordDashboard.css';
import { auth } from '../firebase';

import LandlordDashboardHeader from '../LandlordDashboard/LandlordDashboardHeader';
import RequestQuotePanel from '../LandlordDashboard/RequestQuotePanel';
import PreQuotedWorksPanel from '../LandlordDashboard/PreQuotedWorksPanel';
import LandlordManagePropertiesPanel from '../LandlordDashboard/LandlordManagePropertiesPanel';
import JobRequestForm from '../Job/JobRequestForm';
import PreQuotedWorkForm from '../PreQuotedWork/PreQuotedWorkForm';

const LandlordDashboard = () => {
  const [formType, setFormType] = useState('');
  const [isPopFormVisible, setIsPopFormVisible] = useState(false);
  const [isJobDetailVisible, setIsJobDetailVisible] = useState(false);
  const [isRequestAQuoteVisible, setIsRequestAQuoteVisible] = useState(false);
  const [isPreQuotedWorksVisible, setIsPreQuotedWorksVisible] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [property, setProperty] = useState([]);
  const [selectedPQW, setSelectedPQW] = useState(null);
  const [preQuotedWorks, setPreQuotedWorks] = useState([]);
  const [isMainContentVisible, setIsMainContentVisible] = useState(true);
  const [title, setTitle] = useState('');
  const [jobData] = useState(null);
  // const [user, setUser] = useState('');

  const [selectedJob, setSelectedJob] = useState(null); // Holds the selected job data
  const [tabModes, setTabModes] = useState(null); // Holds tabModes for JobTabs

  // Ref for LandlordManageProperties
  const managePropertiesRef = useRef(null);

  useEffect(() => {
    const loadPreQuotedWorks = async () => {
      try {
        const works = await fetchPreQuotedWorks();
        setPreQuotedWorks(works);
      } catch (error) {
        console.error('LandlordDashboard: Error fetching Pre-Quoted Works:', error);
      }
    };

    loadPreQuotedWorks();
  }, []);

  useEffect(() => {
    const loadProperty = async () => {
      const landlordEmail = auth.currentUser?.email;
  
      if (!landlordEmail) {
        console.error('LandlordDashboard: No logged-in user found.');
        return;
      }
  
      try {
        const properties = await getPropertiesByEmail(landlordEmail);
        if (properties.length > 0) {
          setProperty(properties[0]);
        } else {
          setProperty(null);
        }
      } catch (error) {
        console.error('LandlordDashboard: Error loading property:', error);
      }
    };
  
    loadProperty();
  }, []);

  const handlePQWClick = (work) => {
    setIsMainContentVisible(false);
    setIsRequestAQuoteVisible(false);
    setIsJobDetailVisible(false);
    setSelectedPQW(work);
    setIsPreQuotedWorksVisible(true);
    setFormType('PreQuotedWork');
    // setIsPopFormVisible(true);
  };

  const openForm = (type, propertyId, agentId, readOnly = false) => {
    setIsRequestAQuoteVisible(true);
    setIsPreQuotedWorksVisible(false);
    setIsMainContentVisible(false);
    setIsJobDetailVisible(false);
    setFormType(type);
    setIsReadOnly(readOnly);
    // setIsPopFormVisible(true);

  };

  const closeForm = () => {
    alert('close form');
    setIsPopFormVisible(false);
    setSelectedPQW(null);
    // Trigger refreshGrid in LandlordManageProperties
    if (managePropertiesRef.current) {
      managePropertiesRef.current.refreshGrid();
    }
  };

  const handleRowSelectAction = (jobData, modes) => {
    setSelectedJob(jobData);
    setTabModes(modes);
    setIsMainContentVisible(false); 
    setIsPreQuotedWorksVisible(false);
    setIsRequestAQuoteVisible(false);
    setIsJobDetailVisible(true); 
  };
    

  const resetViews = () => {
    setIsMainContentVisible(true); 
    setIsRequestAQuoteVisible(false);
    setIsPreQuotedWorksVisible(false);
    setIsJobDetailVisible(false); 
  };


  return (
    <div className="dashboard-main-content-container">
      <LandlordDashboardHeader />

      {isMainContentVisible ? (
        <>
          <RequestQuotePanel property={property} setTitle={setTitle} openForm={openForm} />
          <PreQuotedWorksPanel preQuotedWorks={preQuotedWorks} handlePQWClick={handlePQWClick} />
          <LandlordManagePropertiesPanel property={property} managePropertiesRef={managePropertiesRef} onRowSelectAction={handleRowSelectAction} />

        </>
      ) : (
        <>
          <div className="jobs-header">
            {/* <h2>Job Details for job: {selectedJobId}</h2> */}
            {/* <button className="negative-button" onClick={() => setIsMainContentVisible(!isMainContentVisible)}>
              Close Job Detail and Return to Dashboard
            </button> */}
          </div>

          {/* <div className="job-tabs-container"> */}
            {/* <JobTabs jobData={selectedJob} tab={Object.keys(tabModes)[0]} isAdmin={false} tabModes={tabModes} onReturnToDashboard={() => setIsMainContentVisible(true)} /> */}
          {/* </div> */}
        </>
      )}

    {isRequestAQuoteVisible && (
      <>
      <div>
        {/* const JobRequestForm = ({ onClose, propertyId, agentId }) => { */}
        <JobRequestForm propertyId={property.propertyId} agentId={property.agentId} onReturnToDashboard={() => resetViews()} />
      </div>      
      </>
    )}

    {isPreQuotedWorksVisible && (
      <>
        <div>
        {/* const PreQuotedWorkForm = ({ onClose, pqwData, propertyId, agentId }) => { */}
          <PreQuotedWorkForm pqwData={selectedPQW} propertyId={property.propertyId} agentId={property.agentId} onReturnToDashboard={() => resetViews()} />
        </div>
      </>
    )}

    {isJobDetailVisible && (
      <>
        <JobTabs jobData={selectedJob} tab={Object.keys(tabModes)[0]} isAdmin={false} tabModes={tabModes} onReturnToDashboard={() => resetViews()} />
      </>
    )}

      {isPopFormVisible && (
        <div className="pop-form-overlay" onClick={closeForm}>
          <div onClick={(e) => e.stopPropagation()}>
            <PopForm
              formType={formType}
              title={title}
              onClose={closeForm}
              propertyId={property?.propertyId}
              agentId={property?.agentId} 
              jobData={formType === 'PreQuotedWork' ? selectedPQW : jobData} // Conditional logic for jobData
              readOnly={isReadOnly}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandlordDashboard;
