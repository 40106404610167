import React from 'react';
import { NavLink } from "react-router-dom";

const AgentNavLinks = ({ menuLinksRef, onHover, unreadCount, onDashboardClick, onNotificationClick }) => {

  return (
    <>
      <li>
        <NavLink to="/agent-dashboard" ref={(el) => (menuLinksRef.current[0] = el)} onMouseEnter={onHover} onClick={onDashboardClick} >
          <svg><use xlinkHref="#pages"></use></svg>
          <span>Dashboard</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/notifications" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
          <svg> <use xlinkHref="#notifications"></use></svg>
          <span>Notifications</span>
          {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
        </NavLink>
      </li>
    </>
  );
};

export default AgentNavLinks;
