import React, { useEffect, useState } from 'react';
import { fetchTrades } from '../services/tradesService';
import { uploadPhoto } from '../services/dbService';
// import { setDoc, doc } from 'firebase/firestore';
// import { db } from '../firebase';
import '../styles/JobRequestFormJobDetails.css';

const JobRequestFormJobDetails = ({ selectedTrades = [], setSelectedTrades = () => {}, propertyId, onPhotoUpload = () => {}, }) => {
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    const loadTrades = async () => {
      const tradesData = await fetchTrades();
      const enabledTrades = tradesData.filter((trade) => trade.enabled);
      setTrades(enabledTrades.map((trade) => ({ tradeId: trade.id, tradeName: trade.tradename })));
    };
    loadTrades();
  }, []);

  const toggleTradeSelection = (trade) => {
    setSelectedTrades((prevSelected) => {
      const alreadySelected = prevSelected.some((t) => t.tradeId === trade.tradeId);
      if (alreadySelected) {
        return prevSelected.filter((t) => t.tradeId !== trade.tradeId);
      }
      return [
        ...prevSelected,
        {
          tradeId: trade.tradeId,
          tradeName: trade.tradeName,
          jobDetail: '',
          instructionNotes: '',
          furtherDetails: '',
          photos: [],
        },
      ];
    });
  };

  const handleDetailChange = (tradeId, field, value) => {
    setSelectedTrades((prevSelected) =>
      prevSelected.map((t) => (t.tradeId === tradeId ? { ...t, [field]: value } : t))
    );
  };

  const handlePhotoUpload = async (tradeId, files) => {
    const uploadedPhotos = [];
    for (let file of files) {
      const path = `jobs/${propertyId}/${Date.now()}_${file.name}`; 
      const photoURL = await uploadPhoto(file, path);
      uploadedPhotos.push(photoURL);
    }

    setSelectedTrades((prevSelected) =>
      prevSelected.map((t) =>
        t.tradeId === tradeId ? { ...t, photos: [...t.photos, ...uploadedPhotos] } : t
      )
    );

    // Pass the uploaded photo URLs back to the parent for adding to the job record
    onPhotoUpload(uploadedPhotos);
  };


  
  return (
    <div className="job-request-form">
      <div className="trades-selection-panel">
        {trades.map((trade) => (
          <button
            key={trade.tradeId}
            className={`trade-button ${
              selectedTrades.some((t) => t.tradeId === trade.tradeId) ? 'selected' : ''
            }`}
            onClick={() => toggleTradeSelection(trade)}
          >
            {trade.tradeName}
          </button>
        ))}
      </div>

      <div className="selected-trades-panel">
        <h3>Selected Trades</h3>
        {selectedTrades.length > 0 ? (
          selectedTrades.map((trade) => (
            <div key={trade.tradeId} className="trade-detail-row">
              <h4>{trade.tradeName}</h4>
              <textarea
                placeholder="Job Description"
                value={trade.jobDetail || ''}
                onChange={(e) => handleDetailChange(trade.tradeId, 'jobDetail', e.target.value)}
              />
              <textarea
                placeholder="Further Information"
                value={trade.furtherDetails || ''}
                onChange={(e) => handleDetailChange(trade.tradeId, 'furtherDetails', e.target.value)}
              />

              <div className="trade-photo-row">
                <button className="add-photo-button" onClick={() => document.getElementById(`file-input-${trade.tradeId}`).click()}>
                  Add Photos
                </button>
                <input id={`file-input-${trade.tradeId}`} type="file" multiple style={{ display: 'none' }}
                  onChange={(e) => handlePhotoUpload(trade.tradeId, e.target.files)}
                />
                <div className="uploaded-photos">
                  {trade.photos.length > 0 ? (
                    trade.photos.map((photo, index) => (
                      <img key={index} src={photo} alt={`Uploaded ${index + 1}`} className="uploaded-photo" />
                    ))
                  ) : (
                    <p>No photos uploaded.</p>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No trades selected.</p>
        )}
      </div>
    </div>
  );
};

export default JobRequestFormJobDetails;
