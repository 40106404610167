import React, { useState, useEffect } from 'react';
import { ThemeProvider } from './ThemeProvider/ThemeProvider'; // Adjust path as needed
import { BrowserRouter as Router } from 'react-router-dom';
import useAuth from './hooks/useAuth'; // Import the useAuth hook
import AppRouter from './AppRouter'; // Import the refactored router
import { loadTradeColors } from './Shared/tradeDecorator'; // Adjust path as necessary
import { FeedbackProvider } from './context/FeedbackContext'; // Import FeedbackProvider

import './styles/App.css';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');
  const { isAuthenticated, userEmail } = useAuth();

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('light-mode', !newMode);
      localStorage.setItem('dark-mode', newMode ? 'true' : 'false');
      return newMode;
    });
  };

  useEffect(() => {
    const initializeTradeColors = async () => {
      try {
        await loadTradeColors();
        // console.log("Trade colors loaded successfully.");
      } catch (error) {
        console.error("Failed to load trade colors:", error);
      }
    };

    initializeTradeColors();
  }, []);

  return (
    <Router>
      <FeedbackProvider> {/* Wrap everything in FeedbackProvider */}
        <ThemeProvider>
          <AppRouter
            isAuthenticated={isAuthenticated}
            userEmail={userEmail}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        </ThemeProvider>
      </FeedbackProvider>
    </Router>
  );
}

export default App;
