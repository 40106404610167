import React, { useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { generatePQWEmailTextTemplate, generatePQWEmailHTMLTemplate } from '../EmailTemplates/emailTemplates';
import { getNextJobId, insertPreApprovedJob, createNotification, uploadPhoto } from '../services/dbService';
import '../styles/PreQuotedWorkForm.css';
import { auth } from '../firebase';
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { createQuote } from '../services/quoteService';

const PreQuotedWorkForm = ({ pqwData, propertyId, agentId, onReturnToDashboard }) => {
  const landlordEmail = auth.currentUser?.email;
  const [uploadedPhotos, setUploadedPhotos] = useState([]); // State to store uploaded photo URLs

  const handlePhotoUpload = async (files) => {
    const uploaded = [];
    for (let file of files) {
      const path = `jobs/${propertyId}/${Date.now()}_${file.name}`;  // Unique path for each photo
      const photoURL = await uploadPhoto(file, path);
      uploaded.push(photoURL);
    }
    setUploadedPhotos((prev) => [...prev, ...uploaded]); // Add uploaded URLs to state
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };

  if (!pqwData) {
    return (
      <div>
        <p>Loading pre-quoted work details...</p>
      </div>
    );
  }

  const fetchLandlordDetails = async (landlordEmail) => {
    const userDocRef = doc(db, "users", landlordEmail); 
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        firstName: userData.firstName,
        lastName: userData.lastName,
      };
    } else {
      throw new Error("Landlord not found");
    }
  };

  const handleConfirm = async () => {
    try {
      // Fetch landlord details
      const landlordDetails = await fetchLandlordDetails(landlordEmail);
  
      // Fetch property details
      const propertyDetails = await fetchPropertyDetails(propertyId);

      // Fetch the next available job ID for the property
      const jobId = await getNextJobId(propertyId);
  
      const currentTime = serverTimestamp(); // Capture current time once
  
      const jobData = {
        jobDetail: pqwData.title,
        quoteAmount: pqwData.price,
        tradeName: pqwData.trade, 
        propertyId: propertyId,
        completedDate: null,
        contractorName: "In-House",
        furtherDetails: pqwData.jobDetail,
        instructionNotes: null,
        jobId: jobId,
        landlordEmail,
        landlordFirstName: landlordDetails.firstName, 
        landlordLastName: landlordDetails.lastName, 
        paid: false,
        paymentId: null,
        quoteAccepted: true,
        quoteAcceptedAt: currentTime, // Set timestamp here
        quoteTime: null,
        raised: currentTime,
        tradeId: pqwData.id,
        isPQW: true,
        agentId: agentId,
        photos: uploadedPhotos,  // Include uploaded photo URLs
      };
  
      // Insert the pre-approved job
      await insertPreApprovedJob(propertyId, jobId, jobData);

      // Calculate price and VAT
      const beforeVatPrice = pqwData.price / 1.222222222222;
      const vatAmount = pqwData.price - beforeVatPrice;
  
      // Create and insert the quote record
      const quoteData = {
        jobId,
        landlordEmail,
        trade: pqwData.trade,
        instructionNotes: null,
        furtherDetails: pqwData.furtherDetails || null,
        quoteExpiryDate: null,
        quoteDetails: pqwData.jobDetail,
        costBreakdown: [{ 
          description: pqwData.title, 
          id: 1,
          net: beforeVatPrice,
          quantity: "",
          total: pqwData.price,
          type: "Labour",
          unitPrice: "",
          vatAmount: vatAmount,
          vatRate: "20%",
        }],
        createdAt: currentTime,
      };
  
      const status = 3;
      await createQuote(jobId, quoteData, status);
  
      // Add an initial comment for the job
      const initialComment = {
        sender: 'Admin',
        text: `Pre-quoted work confirmed for ${pqwData.jobDetail}`,
        timestamp: new Date(),
        recipient: null,
      };
  
      const commentsRef = doc(db, 'comments', jobId);
      await setDoc(commentsRef, {
        jobId,
        comments: [initialComment],
      });

      // Send email notification to the landlord
      const plainTextBody = generatePQWEmailTextTemplate(jobData.jobId, jobData.landlordFirstName);
      const htmlBody = generatePQWEmailHTMLTemplate(jobData.jobId, jobData.landlordFirstName);
  
      await sendNotificationEmail({
        to: jobData.landlordEmail,
        subject: `${globalConfig.sitename} quote - Job ${jobData.jobId}`,
        text: plainTextBody,
        html: htmlBody,
      });

      const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

      // Create site Notifications
      await createNotification(jobData.jobId, "pqw_requested", {
        admin: globalConfig.adminEmail, // Notify admin
        agent: agentId, // Notify the agent
        landlord: null, // No need to notify landlord
        contractor: null, // Not needed for this event
      }, propertyAddress);
  
      onReturnToDashboard();
    } catch (error) {
      console.error('PreQuotedWorkForm: Error confirming pre-approved work:', error);
      alert('There was an error confirming the work. Please try again.');
    }
  };
  
  return (
    <div className="pre-quoted-work-form">
      <h3>{pqwData.title}</h3>
      <p>{pqwData.jobDetail}</p>
      <p>{pqwData.trade}</p>
      <p><strong>Price:</strong> £{pqwData.price}</p>

      <div className="photo-upload-section">
        <h4>Upload Photos</h4>
        <input
          type="file"
          multiple
          onChange={(e) => handlePhotoUpload(e.target.files)}
        />
        <div className="uploaded-photos">
          {uploadedPhotos.map((photo, index) => (
            <img key={index} src={photo} alt={`Uploaded ${index + 1}`} className="uploaded-photo" />
          ))}
        </div>
      </div>

      <div className="pre-quoted-work-buttons">
        <button className="cancel-btn" onClick={onReturnToDashboard}>Cancel and Return to Dashboard</button>
        <button className="confirm-btn" onClick={handleConfirm}>Confirm Work</button>
      </div>
    </div>
  );
};

export default PreQuotedWorkForm;
