import React, { useState } from 'react';
import Header from '../components/UI/Header';
import SideNav from '../components/UI/SideNav';
import AdministrationContent from './AdministrationContent';

const MainLayout = ({ isCollapsed, setIsCollapsed, isDarkMode, toggleDarkMode, isContractor, }) => {
  const [dashboardKey, setDashboardKey] = useState(0); // State for re-rendering
  const [notificationKey, setNotificationKey] = useState(0); // State for re-rendering

  const handleDashboardClick = () => {
    setDashboardKey((prevKeyD) => prevKeyD + 1); // Increment key to force re-render
  };

  const handleNotificationClick = () => {
    setNotificationKey((prevKeyD) => prevKeyD + 1); // Increment key to force re-render
  };
  // MainLayout
// debugger;
  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header />
      <SideNav
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isContractor={isContractor}
        onDashboardClick={handleDashboardClick}
        onNotificationClick={handleNotificationClick}
      />
      <AdministrationContent 
        dashboardKeykey={dashboardKey}
        notificationKey={notificationKey}
        isCollapsed={isCollapsed}
        isContractor={isContractor}
      />
    </div>
  );
};

export default MainLayout;
