import React, { useEffect, useState } from "react";
import { fetchNotifications, markNotificationAsRead, markNotificationAsUnRead, fetchJobById } from "../services/dbService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import JobTabs from '../Job/JobTabs';
import styles from "../styles/Notifications.module.css";

const Notifications = () => {

  const [notifications, setNotifications] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  // TODO: FIX MODES
  const tabModes = {
    Detail: "readWrite",
    Appointments: "hide",
    Quote: "hide",
    Comments: "readWrite",
    Property: "hide",
  };
  
  const handleMarkAsUnread = async (notificationId) => {
    try {
      await markNotificationAsUnRead(notificationId);
  
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.id === notificationId ? { ...notif, read: false } : notif
        )
      );
    } catch (error) {
      console.error("Error marking notification as unread:", error);
    }
  };

  useEffect(() => {
    const loadNotifications = async () => {
      const data = await fetchNotifications();
      setNotifications(data);
    };

    loadNotifications();
  }, []);

  const handleNotificationClick = async (notificationId, event) => {
    if (event.event.target.tagName === "BUTTON") return; // Ignore button clicks

    try {
      await markNotificationAsRead(notificationId);
      setNotifications((prev) =>
        prev.map((notif) => (notif.id === notificationId ? { ...notif, read: true } : notif))
      );
  
      const jobData = await fetchJobById(event.data.jobId); // Fetch job details
      setSelectedJob(jobData);

    } catch(error) {
      console.error("Error fetching job details:", error);
    }
  };

  const gridOptions = {
    suppressRowClickSelection: true, // Prevent row selection from clicking inside cells
  };

  const columns = [
    { headerName: "JOB ID", field: "jobId", flex: 0.5},
    { headerName: "ADDRESS", field: "address", flex: 1.2},
    { headerName: "MESSAGE", field: "message", flex: 2.0 },
    { headerName: "TIME", field: "createdAt", flex: 0.8},
    { headerName: "STATUS", field: "read", flex: 0.8,
      suppressClickEdit: true,
      cellRenderer: ({ value, data }) => {
        return (
          <span>
            { value ? "✔ Read" : "🔴 Unread"}
            { value && (
              <button
                className={styles.markAsUnreadButton}
                onClick={(e) => {
                  e.stopPropagation(); // Ensures row click doesn't fire
                  handleMarkAsUnread(data.id);
                }}
                style={{ marginLeft: 10 }}
              >
                Mark as Unread
              </button>
            )}
          </span>
        );
      },
    }
  ];

  console.log("Notifications called: " + selectedJob);
  // debugger;

  return (
    <div>
        {selectedJob ? (
          // TODO: FIX THE INTEGRITY OF THE PROPS
          <JobTabs jobData={selectedJob} onReturnToDashboard={null} tab={'Detail'} isAdmin={true} tabModes={tabModes} />
      ) : (
        <>
          <div className="ag-theme-alpine" style={{ height: "85vh", width: "100%" }}>
            <AgGridReact
              rowData={notifications}
              columnDefs={columns}
              rowSelection="single"
              gridOptions={gridOptions}
              suppressCellSelection={true} 
              onRowClicked={(event) => handleNotificationClick(event.data.id, event)}
              pagination={true} 
              paginationPageSize={20}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
