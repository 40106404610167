import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { auth } from './firebase';
import { fetchUserDocument, updateLastLoggedIn } from './services/dbService';
import CompleteProfile from './Agent/CompleteProfile';
import LoginForm from './components/UI/LoginForm';
import MainLayout from './Layouts/MainLayout';
import Dashboard from './Dashboard/Dashboard';
import AgentDashboard from './Agent/AgentDashboard';
import LandlordDashboard from './Landlord/LandlordDashboard';
import ContractorDashboard from './Contractor/ContractorDashboard';
import TransactionEnd from './TransactionEnd/TransactionEnd';

const AppRouter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  const onLoginSuccess = async (user) => {
    try {
      const userDoc = await fetchUserDocument(user.email);
      const role = userDoc?.role;

      if (!role) {
        alert('No valid role found. Please contact support.');
        return;
      }

      await updateLastLoggedIn(user.email);

      setUserRole(role);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error during login flow:', error);
      alert('An error occurred during login. Please try again later.');
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = await fetchUserDocument(user.email);
          setUserRole(userDoc?.role || null);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);


  return (
    <Routes>
      {isAuthenticated ? (
        <>
          {/* Routes without MainLayout */}
          <Route path="/complete-profile" element={<CompleteProfile />} />
          <Route path="/transaction-end" element={<TransactionEnd />} />

          {/* Routes with MainLayout */}
          <Route
            path="/*"
            element={
              <MainLayout>
                <Routes>
                  {(userRole === 'Owner' || userRole === 'Admin') && (
                    <Route path="/admin-dashboard" element={<Dashboard />} />
                  )}
                  {userRole === 'Agent' && (
                    <Route path="/agent-dashboard" element={<AgentDashboard />} />
                  )}
                  {userRole === 'Landlord' && (
                    <Route path="/landlord-dashboard" element={<LandlordDashboard />} />
                  )}
                  {userRole === 'Contractor' && (
                    <Route path="/contractor-dashboard" element={<ContractorDashboard />} />
                  )}
                </Routes>
              </MainLayout>
            }
          />
        </>
      ) : (
        <Route path="*" element={<LoginForm onLogin={onLoginSuccess} />} />
      )}
    </Routes>
  );
};

export default AppRouter;
