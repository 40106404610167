import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { isPlatinumWindowActive } from '../utils/jobVisibility';
import { calcPlatinumEndTime } from'../utils/jobVisibility';
import TradeCellRenderer from '../Shared/TradeCellRenderer';
import { formatTimestamp } from '../Utilities/dateUtils';
import { updateJobBypassHold } from "../services/dbService";

// import { SUBSCRIPTION_MAPPING } from "../services/dbService"; // Assuming this is imported from dbService

const JobApprovedAwaitingContractor = ({ isSingleColumn, onRowSelectAction }) => {

  const [approvedJobs, setApprovedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleReleaseJob = async (jobId) => {
    console.log('JobApprovedAwaitingContractor:handleReleaseJob: ' + jobId );
    try {
      await updateJobBypassHold(jobId);
      console.log(`Job ${jobId} successfully released.`);
      await loadApprovedJobs();
    } catch (error) {
      alert(`Error updating job: ${error.message}`);
    }
  }

  const columnDefsApprovedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.5 },
    { headerName: "TRADE",
      field: "tradeName",
      flex: 0.9,
      filter: true,
      cellRenderer: TradeCellRenderer,
      valueGetter: (params) => {
        return params.data.tradeName === 'Other (please specify)'
          ? params.data.instructionNotes || 'N/A'
          : params.data.tradeName;
      },
    },
    { headerName: "POSTCODE", field: "propertyDetails.postcode", flex: 0.6 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 2.8 },
    { headerName: "PLATINUM UNTIL", 
      field: "changesAt", 
      flex: 0.9,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Ensure value is treated as a Date object
        return date.toLocaleString(); // Format the date in a human-readable format
      }
    },
    { headerName: "VALUE", 
      field: "quoteAmount", 
      flex: 0.5,
      valueFormatter: (params) => {
        const number = params.value ? parseFloat(params.value) : 0;
        return new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(number);
      }
    },
    { headerName: "PAID AT", 
      field: "quoteAcceptedAt", 
      valueFormatter: (params) => formatTimestamp(params.value),
      flex: 0.8,
    },
    { headerName: "RELEASE", 
      flex: 1.0,
      suppressClickEdit: true,
      cellRenderer: ({ data }) => {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleReleaseJob(data.jobId);
            }}
            style={{ marginLeft: 10 }}
          >
            Release Job
          </button>
        );
      }
    }
  ];

  // useEffect(() => {
  //   const loadApprovedJobs = async () => {
  //     try {
  //       const options = { 
  //         status: 2 
  //       }; 
  //       const jobs = await fetchJobs(options); // Fetch jobs with status
  //       let filteredJobs = [];

  //       // Assuming the platinum window end time is calculated using a function like calcPlatinumEndTime
  //       jobs.forEach((job) => {
  //         const quoteAcceptedAt = job.quoteAcceptedAt.toDate(); // Convert Firestore timestamp to JavaScript Date
  //         const platinumEndTime = calcPlatinumEndTime(quoteAcceptedAt, job.jobId);
  //         // Check if quoteAcceptedAt is within platinum window
  //         const isWithinPlatinumWindow = isPlatinumWindowActive(platinumEndTime);

  //         if (isWithinPlatinumWindow) {
  //           job.changesAt = platinumEndTime;
  //           filteredJobs.push(job);
  //         }

  //       });
  
  //       setApprovedJobs(filteredJobs); // Set all jobs directly into state
  //     } catch (error) {
  //       console.error("Error loading approved jobs:", error);
  //     }
  //   };
  
  //   loadApprovedJobs();
  // }, []);
          
  useEffect(() => {
    loadApprovedJobs();
  }, []);

  const loadApprovedJobs = async () => {
    try {
      const options = { status: 2 };
      const jobs = await fetchJobs(options); // Fetch jobs with status

      let filteredJobs = jobs.filter((job) => {
        // Exclude jobs with bypassHold === true
        if (job.bypassHold) {
          return false;
        }

        const quoteAcceptedAt = job.quoteAcceptedAt.toDate(); // Convert Firestore timestamp to JavaScript Date
        const platinumEndTime = calcPlatinumEndTime(quoteAcceptedAt, job.jobId);
        
        // Check if job is within the Platinum window
        const isWithinPlatinumWindow = isPlatinumWindowActive(platinumEndTime);

        if (isWithinPlatinumWindow) {
          job.changesAt = platinumEndTime;
          return true;
        }

        return false;
      });

      setApprovedJobs(filteredJobs); // Set filtered jobs into state
    } catch (error) {
      console.error("Error loading approved jobs:", error);
    }
  };
  
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    if (event.event && event.event.target.tagName === "BUTTON") return; // Ignore button clicks

    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'hide',
      Property: 'hide',
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <GridPanel
      title="Jobs Approved Awaiting Distribution"
      columnDefs={columnDefsApprovedJobs}
      rowData={approvedJobs}
      isSingleColumn={isSingleColumn}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick}
    />
  );
};

export default JobApprovedAwaitingContractor;
