// src/Job/JobRequestForm.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import JobRequestFormJobDetails from './JobRequestForm-JobDetails';
import { insertJobWithNumbering } from '../services/jobService';
import { auth } from '../firebase';
import { generateRAQEmailTextTemplate, generateRAQEmailHTMLTemplate } from '../EmailTemplates/emailTemplates';
import { sendNotificationEmail } from "../Shared/EmailClient";
import { globalConfig } from '../config';
import { createNotification } from "../services/dbService";
import '../styles/JobRequestForm.css';

const JobRequestForm = ({ propertyId, agentId, onReturnToDashboard }) => {

  const [formData, setFormData] = useState({});
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const landlordEmail = auth.currentUser?.email;
  const [jobId, setJobId] = useState();
  const [landlordFirstName, setLandlordFirstName] = useState('');
 

  const fetchLandlordDetails = async (landlordEmail) => {
    const userDocRef = doc(db, "users", landlordEmail); 
    const userDoc = await getDoc(userDocRef);
  
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        firstName: userData.firstName,
        lastName: userData.lastName,
      };
    } else {
      throw new Error("Landlord not found");
    }
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) throw new Error("Property ID is missing");
  
    const propertyDocRef = doc(db, "properties", propertyId);
    const propertyDoc = await getDoc(propertyDocRef);
  
    if (propertyDoc.exists()) {
      const propertyData = propertyDoc.data();
      return {
        address: propertyData.address || "Unknown Address",
        city: propertyData.companyCity || "Unknown Town/City",
        postcode: propertyData.postcode || "Unknown Postcode",
      };
    } else {
      throw new Error(`Property not found for ID: ${propertyId}`);
    }
  };


  useEffect(() => {
    const isValid = selectedTrades.length > 0 && selectedTrades.every(trade => trade.jobDetail.trim() !== '');
    setIsFormValid(isValid);
  }, [selectedTrades]);


  const handlePhotoUpload = (newPhotos) => {
    setUploadedPhotos((prev) => [...prev, ...newPhotos]);
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      try {
        // Fetch property details
        const propertyDetails = await fetchPropertyDetails(propertyId);
        
        // Fetch landlord details
        const landlordDetails = await fetchLandlordDetails(landlordEmail);
        setLandlordFirstName(landlordDetails.firstName);
  
        for (const trade of selectedTrades) {
          const jobData = {
            ...formData,
            tradeId: trade.tradeId,
            tradeName: trade.tradeName,
            jobDetail: trade.jobDetail,
            instructionNotes: trade.instructionNotes,
            furtherDetails: trade.furtherDetails,
            photos: uploadedPhotos,
            raised: new Date(),
            quoteAmount: '',
            quoteTime: '',
            quoteAccepted: false,
            quoteAcceptedAt: null,
            landlordEmail,
            landlordFirstName: landlordDetails.firstName, 
            landlordLastName: landlordDetails.lastName, 
            propertyId,
            paid: false,
            paymentId: '',
            status: 0,
            isPQW: false,
            agentId,
          };
  
          const jobId = await insertJobWithNumbering(jobData, propertyId);
          setJobId(jobId);
  
          // Inline Comment Logic
          const messageData = {
            sender: 'Admin',
            text: `Quote Requested for ${trade.jobDetail}`,
            timestamp: serverTimestamp(),
          };
  
          const commentsRef = doc(db, 'comments', jobId);
          const commentsSnap = await getDoc(commentsRef);
  
          if (commentsSnap.exists()) {
            await updateDoc(commentsRef, {
              comments: arrayUnion({
                ...messageData,
                timestamp: new Date(),
              }),
            });
            console.log('Comment added to existing document:', messageData);
          } else {
            await setDoc(commentsRef, {
              jobId,
              comments: [
                {
                  ...messageData,
                  timestamp: new Date(),
                },
              ],
            });
            console.log('Created new document with comment:', messageData);
          }

          const propertyAddress = propertyDetails.address + ", " + propertyDetails.city + ", " + propertyDetails.postcode;

          // Create Site Notifications
          await createNotification(jobId, "quote_requested", {
            admin: globalConfig.adminEmail, // Notify admin
            agent: agentId, // Notify the agent
            landlord: null, // No need to notify landlord
            contractor: null // Not needed for this event
            }, propertyAddress,
          );
      }
  
        // Send email notification to the landlord
        const plainTextBody = generateRAQEmailTextTemplate(jobId, landlordFirstName);
        const htmlBody = generateRAQEmailHTMLTemplate(jobId, landlordFirstName);
  
        await sendNotificationEmail({
          to: landlordEmail,
          subject: `${globalConfig.sitename} quote - Job ${jobId}`,
          text: plainTextBody,
          html: htmlBody,
        });
  
        onReturnToDashboard();
      } catch (error) {
        console.error('Error inserting jobs or comments:', error);
      }
    }
  };
  

  return (
    <div className="job-request-form">
      
      <div className='job-request-return'>
        <button className="decline-button" 
        onClick={() => onReturnToDashboard()}>
        Return to Dashboard
        </button>
      </div>
      
      <div className='job-request-header'>

        <h2>Instructions</h2>

        <p>
          1. Select a trade type for the works you require a quote for. This will ensure the request is directed to the right management team
        </p>
        <p>
          2. Provide a brief job description about the work you require a quote for. e.g. <i>'Paint Hallway walls and ceiling'</i>
        </p>
        <p>
          3. Add any further information that may help when requesting a quote, the size of a room, or any special requirements.
        </p>
        <p>
          4. When complete, click send details for a quote and our team will evauluate your request and provide an estimate for the work you require. Please note: the team may have to contact you for clarification on a request that doesnt contain enough information.
        </p>

      </div>

      <JobRequestFormJobDetails
        formData={formData}
        setFormData={setFormData}
        selectedTrades={selectedTrades}
        setSelectedTrades={setSelectedTrades}
        propertyId={propertyId}
        onPhotoUpload={handlePhotoUpload}
      />
      
      <div className="form-navigation">
        <button onClick={handleSubmit} disabled={!isFormValid}>Send Details for a Quote</button>
      </div>
    </div>
  );
};

export default JobRequestForm;
