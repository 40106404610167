import React, { useState, useEffect } from "react";
import { fetchAllUsers } from '../services/dbService';
import { formatTimestamp, formatDateOnly } from '../Utilities/dateUtils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/SettingsUsers.css';
import ProfileForm from '../ProfileForm/ProfileForm'; 

const SettingsUsers = (props) => {
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize] = useState(18);
  const [selectedUser, setSelectedUser] = useState(null);


  const handleRowClick = (row) => {
    setSelectedUser(row.data); // Capture the clicked user's data
  };

  useEffect(() => {
    const loadAllUsers = async () => {
      try {
        const users = await fetchAllUsers();
        setRowData(users);
      } catch (error) {
        console.error('SettingsUser: Error fetching Users: ', error);
      }
    }
    loadAllUsers()
  }, []);

  const columnDefs = [
    { field: "firstName", headerName: "First Name", flex: 0.5 },
    { field: "lastName", headerName: "Last Name", flex: 0.6 },
    { field: "companyName", headerName: "Company", flex: 0.6 },
    { field: "role", headerName: "Role", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 0.9 },
    { field: "mobileNumber", headerName: "Mobile", flex: 0.6 },
    { headerName: "Invite Date", field: "invitedAt", flex: 0.6 ,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "Last Logged In", field: "lastLoggedIn", flex: 0.6 ,
      valueFormatter: (params) => formatTimestamp(params.value) },
    { field: "isProfileComplete", headerName: "Complete", flex: 0.4},
    ];

    return (
      <div className="settings-users-container">
        {selectedUser ? (
        <>
          <h2>Edit User Profile</h2>
          <ProfileForm user={selectedUser} onClose={() => setSelectedUser(null)} />
        </>
        ) : (
          <div className="ag-theme-alpine" style={{ height: '80vh', width: '100%' }}>
            <div className="users-heading">
              <h2>Users</h2>
            </div>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={paginationPageSize}
              suppressCellSelection={true} 
              // suppressRowClickSelection={true}
              suppressCellFocus={true}
              onRowClicked={handleRowClick}
            />
          </div>
        )}
      </div>
    );
} 

export default SettingsUsers;
