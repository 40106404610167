import React, { useState, useEffect } from 'react';
import { fetchAgentData } from '../services/dbService';
import InviteLandlordForm from './AgentInviteLandlordForm';
import ManageProperties from './AgentManageProperties';
import JobTabs from '../Job/JobTabs';
import { auth } from '../firebase';
import '../styles/AgentDashboards.css';
import '../styles/FormsDefault.css';

import { useTheme } from '../ThemeProvider/ThemeProvider';

const AgentDashboard = () => {

  const [activePanel, setActivePanel] = useState(null);
  const [agentData, setAgentData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null); // To hold job data
  // const [tabModes, setTabModes] = useState(null); // To hold tab modes
  const { isDarkMode } = useTheme();

  useEffect(() => {
    setActivePanel(null); 
  }, []);

  const tabModes = {
    Property: 'hide',
    Detail: 'readOnly',
    Comments: 'readOnly',
    Quote: 'readOnly',
    Appointments: 'hide',
  };

  const onRowSelectAction = (jobData, tabModes) => {
    setSelectedJob(jobData); // Set the selected job
    // setTabModes(tabModes); // Set the tab modes
    setActivePanel('jobTabs'); // Switch to JobTabs
  };

  useEffect(() => {
    const loadAgentData = async () => {
      try {
        const userEmail = auth.currentUser?.email;
        if (!userEmail) {
          throw new Error('User is not logged in.');
        }
        const data = await fetchAgentData(userEmail);
        setAgentData(data);
      } catch (err) {
        console.error('Error fetching agent data:', err.message);
        setError(err.message);
      }
    };

    loadAgentData();
  }, []);
    
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!agentData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="agent-dashboard">

      {/* <div className="dashboard-header">
        <h1>Agent Dashboard</h1>
        <button className="mode-toggle" onClick={toggleDarkMode}>
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>


      <div className="dashboard-header">
        <h1>&nbsp;</h1>
        <button className="mode-toggle" onClick={toggleDarkMode}>
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div> */}

      {activePanel === null && (
        <div className='agent-dashboard-welcome-panel'>
          <p>
            To add any potential future home owner, please click the "invite landlord" button. This will allow the future homeowner to communicate within the hub to both the admin, and contractors completing any works at the property. You, as the agent will have complete visibility of all communications and can check on any works whenever you feel, or simply allow CMH to do its job and allow the commission to come in once works are completed
          </p>
          <p>
            Please check on our progress tabs to see the status of all and any works either agreed or completed within the hub, whether a job had been quoted for, or is close to completion, all is visible within our simplistic yet streamlined Hub
          </p>
        </div>
        )}

      {/* Invite Landlord Panel */}
      {activePanel === 'inviteLandlord' && (
        <div className="dashboard-panel">
          <div className={`panel-header ${isDarkMode ? 'dark' : 'light'}`}>
            <h2>Invite a Landlord to Join Complete Maintenance Hub</h2>
            <button className="negative-button" onClick={() => setActivePanel('manageProperties')}>
              Return to Properties Dashboard
            </button>
          </div>

          <p>Send an invitation to a landlord to join the platform.</p>
          <InviteLandlordForm agentData={agentData} onSubmit={() => setActivePanel(null)} onClose={() => setActivePanel(null)} />
        </div>
      )}
  
      {/* Manage Properties Panel */}
      {activePanel === 'manageProperties' && (
        <div className="dashboard-panel">
          <div className={`panel-header ${isDarkMode ? 'dark' : 'light'}`}>
            <h2>Properties Dashboard</h2>
            <button className="negative-button" onClick={() => setActivePanel('inviteLandlord')} >
              Invite a Landlord to join Complete Maintenance Hub
            </button>
          </div>
          <p>View the properties you have invited to Complete Maintenance Hub</p>
          <ManageProperties agentData={agentData} onRowSelectAction={onRowSelectAction} />
        </div>
      )}

      {/* Job Tabs Panel */}
      {activePanel === 'jobTabs' && selectedJob && (
        <div className="dashboard-panel">
          <div className={`panel-header ${isDarkMode ? 'dark' : 'light'}`}>
            <h2>Details</h2>
            <button className="negative-button" onClick={() => setActivePanel('manageProperties')}>
              Return to Properties Dashboard
            </button>
          </div>
          <JobTabs jobData={selectedJob} tab={Object.keys(tabModes)[1]} isAdmin={false} tabModes={tabModes} onReturnToDashboard={() => setActivePanel('manageProperties')} />
        </div>
      )}
  
      {/* Fallback Buttons if No Panel is Active */}
      {activePanel === null && (
        <div className="dashboard-panel fallback-buttons">
          <button className="dashboard-button" onClick={() => setActivePanel('inviteLandlord')} >
            Invite a Landlord to join Complete Maintenance Hub
          </button>
          <button className="dashboard-button" onClick={() => setActivePanel('manageProperties')} >
            View your Properties Dashboard
          </button>
        </div>
      )}

    </div>
  );
};

export default AgentDashboard;
