import React from 'react';
import { NavLink } from "react-router-dom";

const AdminNavLinks = ({ menuLinksRef, onHover, unreadCount, onDashboardClick, onNotificationClick }) => {
    
    return (
      <>
      <li>
        <NavLink to="/dashboard" ref={(el) => (menuLinksRef.current[0] = el)} onMouseEnter={onHover} onClick={onDashboardClick} >
          <svg><use xlinkHref="#pages"></use></svg>
          <span>Dashboard</span>
        </NavLink>
      </li>
  
      <li>
        <NavLink to="/notifications" ref={(el) => (menuLinksRef.current[1] = el)} onMouseEnter={onHover} onClick={onNotificationClick}>
          <svg><use xlinkHref="#notifications"></use></svg>
          <span>Notifications</span>
          {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
          </NavLink>
      </li>
  
      <li>
        <NavLink to="/settings" ref={(el) => (menuLinksRef.current[2] = el)} onMouseEnter={onHover}>
          <svg> <use xlinkHref="#settings"></use> </svg>
          <span>Settings</span>
        </NavLink>
      </li>
    </>
    );
};

export default AdminNavLinks;
